<script>
import "flatpickr/dist/flatpickr.css";

export default {
  page: {},
  data() {
    return {
      sortDirection: 0, // 1 for ascending, -1 for descending
    };
  },
  created() {},
  props: {
    sortKey: String,
  },
  computed: {
    shouldHideUpArrow() {
      return this.sortDirection === -1;
    },
    shouldHideDownArrow() {
      return this.sortDirection === 1;
    },
  },
  methods: {
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === 0 ? 1 : -this.sortDirection;
      this.$emit('direction', this.sortDirection);
    },
  },
  components: {},
  mounted() {},
  watch: {
    sortKey() { this.sortDirection = 0; },
  },
};
</script>

<template>
  <div class="icon-container" @click="toggleSortDirection">
    <i class="ri-arrow-up-s-fill" :class="{ 'd-none': shouldHideUpArrow, 'adjust-arrow': shouldHideDownArrow  }"></i>
    <i class="ri-arrow-down-s-fill" :class="{ 'd-none': shouldHideDownArrow, 'adjust-arrow': shouldHideUpArrow }"></i>
  </div>
</template>

<style scoped>
  .icon-container {
    position: relative;
    display: inline-block;
  }

  .ri-arrow-up-s-fill,
  .ri-arrow-down-s-fill {
    position: absolute;
    top: -16px;
    left: 0;
  }

  .ri-arrow-down-s-fill {
    margin-top: 5px;
  }

  .adjust-arrow {
    margin-top: 2px;
  }
</style>