<script>
import "flatpickr/dist/flatpickr.css";
import axios from 'axios';
import PermissionError from '../errors/permission-error.vue';
import moment from 'moment';

export default {
  page: {},
  data() {
    return {
      logs: [],
      logModal: false,
      hasPermissions: null,
    };
  },
  created() {},
  props: {
    id          : Number,
    model       : String,
  },
  methods: {

    getLogs() {
      axios.get('/api/activity/get-logs',{ params:{ 'id': this.id, 'model': this.model }})
      .then(response => {
        this.logs = this.decrypt(response.data.logs);
        this.hasPermissions = true;
      })
      .finally(() => {
        this.logModal = true;
      })
      .catch(err => {
        if (err.response.data.no_permission) { this.hasPermissions = false; }
      });
    },

    formattedDate(date){return moment(date).format('DD-MM-YYYY | hh:mm:ss A');},
    //

  },
  components: {
    PermissionError,
  },
  mounted() {},
  watch: {},
};
</script>

<template>

    <li class="list-inline-item log" data-bs-toggle="tooltip" data-bs-trigger="hover"
    data-bs-placement="top" title="Log" v-if="hasPermission('sport.get')">
      <b-link class="text-primary d-inline-block log-item-btn" @click="getLogs()">
        <i class="ri-eye-fill fs-16"></i>
      </b-link>
    </li>

    <!-- log modal -->
    <b-modal v-model="logModal"
    body-class="p-3 text-center"
    size="xl"
    :title="$t('t-change-log')"
    header-class="bg-light p-3"
    modal-class="flip"
    hide-footer centered scrollable>

    <div v-if="logs.length">
      <b-row class="align-items-start px-3" style="min-height: 2rem;">
        <b-col sm="4">
          <div class="text-dark rounded p-2 fw-bold">{{ $t('t-action') }}</div>
        </b-col>
        <b-col sm="4">
          <div class="text-dark rounded p-2 mt-2 mt-sm-0 fw-bold">{{ $t('t-modified-by') }}</div>
        </b-col>
        <b-col sm="4">
          <div class="text-dark rounded p-2 mt-2 mt-sm-0 fw-bold">{{ $t('t-date') }}</div>
        </b-col>
      </b-row>
      <b-accordion>
        <b-accordion-item v-for="(log, index) of logs" :key="index">
          <template #title>
            <b-row class="align-items-start w-100 text-center" style="min-height: 2rem;">
                <b-col sm="4">
                  <div class="text-dark rounded p-2 fw-bold">{{ $t(`${log.description}`) }}</div>
                </b-col>
                <b-col sm="4">
                  <div class="text-dark rounded p-2 mt-2 mt-sm-0 fw-bold">{{ log.causer.name }}</div>
                </b-col>
                <b-col sm="4">
                  <div class="text-dark rounded p-2 mt-2 mt-sm-0 fw-bold">{{ formattedDate(log.created_at) }}</div>
                </b-col>
            </b-row>
          </template>

          <div v-if="log.properties.old">
            <b-row class="align-items-start" style="min-height: 2rem;" v-for="(old, key) of log.properties.old" :key="key">
              <b-col sm="3">
                <div class="text-dark rounded p-2 fw-bold border-end">{{ $t(`t-${key}`) }}</div>
              </b-col>
              <b-col sm="3">
                <div class="text-dark rounded p-2">{{ old }}</div>
              </b-col>
              <b-col sm="3">
                <div class="text-dark rounded p-2 mt-2 mt-sm-0 fw-bold"><i class="ri-arrow-right-fill"></i></div>
              </b-col>
              <b-col sm="3">
                <div class="text-dark rounded p-2 mt-2 mt-sm-0">{{ log.properties.attributes[key] }}</div>
              </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row class="align-items-start" style="min-height: 2rem;" v-for="(attribute, key) of log.properties.attributes" :key="key">
              <b-col sm="5">
                <div class="text-dark rounded p-2 fw-bold text-end">{{ $t(`t-${key}`) }}</div>
              </b-col>
              <b-col sm="1">
                <div class="text-dark rounded p-2 mt-2 mt-sm-0 fw-bold text-end">:</div>
              </b-col>
              <b-col sm="5">
                <div class="text-dark rounded p-2 text-start">{{ attribute }}</div>
              </b-col>
            </b-row>
          </div>

        </b-accordion-item>
      </b-accordion>
    </div>

    <div v-else>
      <!-- if my user dont have permissions -->
      <permission-error :hasPermissions="hasPermissions"></permission-error>

      <b-row class="align-items-start" style="min-height: 2rem;" v-if="!hasPermission">
        <b-col sm="12">
          <div class="text-dark rounded p-2 fw-bold">{{ $t('t-no-logs') }}</div>
        </b-col>
      </b-row>
    </div>
    </b-modal>
</template>