import CryptoJS from 'crypto-js';
import Swal from "sweetalert2";

export const myMixin  = {
    data() { return {}; },
    methods: {
      /**
       * Check if the user has a specific permission.
       * 
       * @param {string} permission - The permission to check.
       * @return {boolean} - Returns true if the user has the specified permission, otherwise returns false.
       */
      hasPermission(permission) {
          // Verify if the user has the specified permission
          const user = JSON.parse(localStorage.getItem('user')); // Get user data from localStorage or another source
          if (user && user.permissions) {
            return user.permissions.includes(permission);
          }
          return false; // The user doesn't have the permission if it's not found in their permissions
      },

      /**
       * Decrypts an encrypted data object.
       * @param {string} data - The encrypted data object as a string.
       * @returns {any} - The decrypted data.
       */
      decrypt(data) {
          // Parse the base64-encoded data into an object
          const encryptedData = JSON.parse(atob(data));
  
          // Decrypt the value using the encryption key and initialization vector (IV)
          const decrypted = CryptoJS.AES.decrypt(
          encryptedData.value,
          CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPTION_KEY),
          {
            iv: CryptoJS.enc.Base64.parse(encryptedData.iv),
          }
          );
  
          // Parse the decrypted result from UTF-8 to a JavaScript object
          return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      },

      // /**
      //  * Encripta un objeto de datos.
      //  * @param {any} data - El objeto de datos a encriptar.
      //  * @returns {string} - El objeto encriptado como una cadena codificada en base64.
      //  */
      // encrypt(data) {
      //   // Convierte el objeto de datos a una cadena JSON
      //   const jsonData = JSON.stringify(data);

      //   // Encripta la cadena JSON usando AES
      //   const encrypted = CryptoJS.AES.encrypt(
      //     jsonData,
      //     CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPTION_KEY),
      //     { iv: CryptoJS.lib.WordArray.random(16) } // IV aleatorio
      //   );

      //   // Convierte el resultado en base64 y devuelve como cadena
      //   return btoa(encrypted.toString());
      // },

      // /**
      //  * Encrypts data before sending it to the backend.
      //  * @param {any} data - The data object to be encrypted.
      //  * @returns {string} - The encrypted data as a string.
      //  */
      // encrypt(data) {
      //   // Convert the data object to a JSON string
      //   const jsonData = JSON.stringify(data);

      //   // Encrypt the JSON data using AES encryption
      //   const encrypted = CryptoJS.AES.encrypt(
      //       jsonData,
      //       CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPTION_KEY),
      //       { iv: CryptoJS.lib.WordArray.random(16) } // Use a random IV for each encryption
      //   );

      //   // Convert the encrypted data to a base64-encoded string
      //   const encryptedString = btoa(encrypted.toString());

      //   return encryptedString;
      // },

      /**
       * Initiates the print operation in the browser.
       *
       * @returns {void} - This function does not return a value.
       */
      print() { window.print(); },

      /**
       * Displays a custom alert using SweetAlert.
       * @param {string} icon - The icon to show in the alert.
       * @param {string} title - The title of the alert.
       * @param {number} timer - The duration of the alert in milliseconds.
       */
      showAlert(icon, title, timer) {

        Swal.fire({
          icon: icon,
          title: title,
          showConfirmButton: false,
          timer: timer,
        });
      },

      /**
       * Handle checkbox checking logic.
       * @param {string} list - The ID of the container containing the checkboxes.
       * @param {string} checkAll - The ID of the 'CheckAll' checkbox.
       */
      checkOne(list, checkAll) {
        // Select all checkboxes in the container
        const checkboxes = document.querySelectorAll(`#${list} .form-check-input:not([disabled])`);

        // Count the number of checked checkboxes excluding the 'CheckAll' checkbox
        const checkedCount = document.querySelectorAll(`#${list} .form-check-input:checked:not(#${checkAll})`).length;

        // Display or hide the remove actions based on whether there are checked checkboxes
        document.getElementById('remove-actions').style.display = checkedCount ? 'block' : 'none';

        // Check if all checkboxes, excluding the 'CheckAll' checkbox, are checked
        const allChecked = checkedCount === checkboxes.length - 1;

        // Set the 'CheckAll' checkbox state based on whether all checkboxes are checked
        document.getElementById(checkAll).checked = allChecked;
      },

      /**
       * Waits for the input with the specified ref to be ready and performs a custom action.
       *
       * @param {string} refName - The name of the ref corresponding to the input element.
       * @param {function} actionCallback - The callback function to be executed once the input is ready.
       * @returns {Promise} A promise that resolves to the input element.
       */
      performActionOnInput: async function (refName, actionCallback) {
        // Return a promise that resolves once the input is ready
        return new Promise(resolve => {
          // Function to check if the input is ready
          const checkInput = function () {
            // Get the input element using the provided refName
            const targetInput = this.$refs[refName];
            
            // If the input is ready, resolve the promise
            if (targetInput) {
              resolve(targetInput);
            } else {
              // If the input is not ready, wait for a short interval and check again
              setTimeout(checkInput, 100);
            }
          };
          
          // Start checking for the input
          checkInput.call(this);

          // Once the input is ready, execute the provided callback
          resolve.then(actionCallback);
        });
      },

      /**
       * Function to focus on the next focusable element in the document.
       *
       * @returns {void} - This function does not return a value.
       */
      focusNextElement() {
        // Get all focusable elements in the document
        const focusableElements = Array.from(document.querySelectorAll('input, select, [tabindex]'));
        // const focusableElements = Array.from(document.querySelectorAll('a, button, input, select, textarea, [tabindex]'));

        // Find the index of the currently focused element in the list of focusable elements
        const currentIndex = focusableElements.indexOf(document.activeElement);

        // Calculate the index of the next focusable element in a circular manner
        const nextIndex = (currentIndex + 1) % focusableElements.length;

        // Get the reference to the next focusable element
        const nextElement = focusableElements[nextIndex];

        // console.log(nextElement);

        // Focus on the next focusable element
        nextElement ? nextElement.focus() : null;
      },

      /**
       * Function to navigate to the next input field when the "Enter" key is pressed.
       *
       * @param {string} refName - The name of the current input field.
       * @param {Array} inputRefs - An array containing references to all input fields.
       * @returns {void} - This function does not return a value.
       */
      enterAsTab(refName, inputRefs) {
        // Check if the "Enter" key is pressed
        if (event.key === "Enter") {
          // Get the index of the current input field in the array
          const currentIndex = inputRefs.indexOf(refName);

          // Calculate the index of the next input field in a circular manner
          const nextIndex = (currentIndex + 1) % inputRefs.length;

          // Get the reference to the next input field
          const nextInputRef = this.$refs[inputRefs[nextIndex]];

          if (nextInputRef && typeof nextInputRef.open === 'function') {
            // Focus on the next focusable element in the document
            this.focusNextElement();

            // Open the next input field (assuming it's a type that supports 'open')
            nextInputRef.open();
          } else {
            // For other types of fields, use the standard focus method
            nextInputRef.focus();
          }
        }
      },

      handleEnterKey(e) {
        // Check if the pressed key is not 'Enter'
        if (e.key !== 'Enter') return;
  
        // Prevent the default behavior of the event (e.g., submitting a form)
        e.preventDefault();
  
        // Select focusable elements using the specified CSS selector
        const focusableSelectors = 'button[tabbable], select, textarea, input:not([readonly], textarea, [tabindex]:not([tabindex="-1"])';
  
        const focusableElements = document.querySelectorAll(focusableSelectors);
        
        // Convert NodeList to Array and filter out elements with zero dimensions
        const visibleFocusableElements = Array.from(focusableElements).filter(el => el.offsetWidth > 0 && el.offsetHeight > 0);
  
        // Find the index of the currently focused element in the filtered array
        const currentIndex = visibleFocusableElements.indexOf(e.target);
  
        // Get the next focusable element
        const nextElement = visibleFocusableElements[currentIndex + 1];
  
        nextElement && (
          typeof nextElement.focus === 'function' ? nextElement.focus() :
          typeof nextElement.select === 'function' && nextElement.select()
        );
      },

      /**
       * Validates the order property in the event object.
       *
       * @param {object} event - The event object containing the order property to be validated.
       *
       * @return {void} - Modifies the order property in the event object. If the value is an empty string or 0, sets it to null.
       */
      validateOrder(event) {
        // Check if the order property is an empty string or 0
        if (event.order === '' || parseInt(event.order) === 0) {
          // If the value is an empty string or 0, set the value to null
          event.order = null;
        }
      },

      /**
       * Validates the code property in the event object.
       *
       * @param {object} event - The event object containing the code property to be validated.
       *
       * @return {void} - Modifies the code property in the event object. If the value is an empty string or 0, sets it to null.
       */
      validateCode(event) {
        // Check if the code property is an empty string or 0
        if (event.code === '' || parseInt(event.code) === 0) {
          // If the value is an empty string or 0, set the value to null
          event.code = null;
        }
      },

      /**
       * Validates a specific property in the event object.
       *
       * @param {object} event - The event object containing the property to be validated.
       * @param {string} propertyName - The name of the property to be validated.
       *
       * @return {void} - Modifies the specified property in the event object. If the value is an empty string or 0, sets it to null.
       */
      validateProperty(event, propertyName) {
        // Get the value of the specified property
        const propertyValue = event[propertyName];

        // Check if the value of the property is an empty string or 0
        if (propertyValue === '' || parseInt(propertyValue) === 0) {
            // If the value is an empty string or 0, set the value to null
            event[propertyName] = null;
        }
      },

      /**
       * Validates if the submitted flag is true and all values in the 'event' object are not null, undefined, or empty strings.
       *
       * @param {boolean} submitted - A flag indicating if the form has been submitted.
       * @param {object} event - An object containing form field values.
       * @returns {boolean} - True if all values are valid, false otherwise.
       */
      validateRequiredFields(submitted, event) {
        return submitted && Object.values(event).every(value => value !== null && value !== undefined && value !== '');
      },

      /**
       * Set the active tab in a modal.
       *
       * @param {string} tabHref - The href of the tab to be activated.
       */
      setTab(tabHref) {

        // Deactivate all tab content panels
        document.querySelectorAll('.tab-pane').forEach((tab) => {
          tab.classList.remove('active');
        });

        // Activate the default tab and display its content
        this.$nextTick(() => {
          // Find the default tab based on the provided 'tabHref'
          const defaultTab = document.querySelector(tabHref);

          // Check if the default tab exists
          if (defaultTab) {
            // Add the 'active' class to the default tab
            defaultTab.classList.add('active');

            // Find the corresponding content panel using the 'href' attribute
            const defaultContent = document.querySelector(defaultTab.getAttribute('href'));

            // Check if the corresponding content panel exists
            if (defaultContent) {
              // Add the 'active' class to the corresponding content panel
              defaultContent.classList.add('active');
            }
          }
        });

        // Activate the default tab link and deactivate others
        document.querySelectorAll('.nav-link').forEach((link) => {
          // Check if the link is not inside the specific block with class 'nav-tabs-custom'
          if (!link.closest('.nav-tabs-custom')) {
            // Get the 'href' attribute value of the current link
            const dataTabHref = link.getAttribute('href');
            
            // Check if the 'href' attribute matches the specified 'tabHref'
            if (dataTabHref === tabHref) {
              // Add the 'active' class if there's a match
              link.classList.add('active');
            } else {
              // Remove the 'active' class if there's no match
              link.classList.remove('active');
            }
          }
        });
      },

      /**
       * Unchecks all checkboxes.
       */
      uncheckAll() {
        // Uncheck the parent checkbox if checked.
        this.$refs.chk_parent.checked ? this.$refs.chk_parent.checked = false : null;

        // Uncheck all child checkboxes.
        this.$refs.chk_child.forEach(checkbox => { checkbox.checked = false; });

        // Hide the remove actions element.
        document.getElementById("remove-actions").style.display = 'none';
      },
    },
  };