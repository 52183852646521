<template>
 <router-view></router-view>

</template>

<script>

import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_URL;

axios.interceptors.request.use(
  (config) => {

    if (localStorage.getItem('token')) { config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`; }

    showPreloader();

    return config;
  },
  (error) => {

    hidePreloader();

    return Promise.reject(error);
  }
);

// Initialize a flag to track if a user fetching operation is in progress
let isFetchingUser = false;

// Intercept Axios responses to handle errors
axios.interceptors.response.use(

  (response) => {

    hidePreloader();

    return response; 
  }, // Return the original response for successful requests

  (error) => {

    console.log(error);
    // Check if the 'no_permission' property exists in the error response data
    if (error.response && error.response.data && error.response.data.no_permission) {
      // Check if a user fetching operation is not already in progress
      if (!isFetchingUser) {
        // Set the flag to indicate that we are fetching the user
        isFetchingUser = true;

        // Perform a request to get the updated user data
        axios.get('/api/user/get-user', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
        .then(response => {
          // Update user information in local storage with the fetched data
          localStorage.setItem('user', JSON.stringify(this.decrypt(response.data.user)));

          // Reset the flag after successfully obtaining the user
          isFetchingUser = false;
        })
        .catch(error => {
          // Handle errors if necessary, such as logging the error
          console.error('Error fetching user:', error);

          // Reset the flag in case of an error during user fetching
          isFetchingUser = false;
        });
      }

      // Call your global function here (if applicable)
    }

    if (error.response && error.response.status === 401) {

      localStorage.clear();

      sessionStorage.clear();

      window.location.href = '/login';
    }

    hidePreloader();

    // Reject the promise with the error to propagate it further
    return Promise.reject(error);
  }
);

const showPreloader = () => {
  const preloader = document.getElementById("preloader");
  preloader && ((preloader.style.opacity = "0.5"), (preloader.style.visibility = "visible"));
};

const hidePreloader = () => {
  const preloader = document.getElementById("preloader");
  preloader && ((preloader.style.opacity = "0"), (preloader.style.visibility = "hidden"));
};

export default {
  name: 'App',
  components: {
  },
}
</script>
