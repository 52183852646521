<template>
    <b-row v-if="hasPermissions === false">
      <b-col lg="12">
        <div class="text-center pt-4">
          <div class="mt-n4">
            <h1 class="display-1 fw-medium">401</h1>
            <h3 class="text-uppercase">{{ $t('t-page-restricted') }}</h3>
            <p class="text-muted mb-4">{{ $t('t-no-have-permissions') }}</p>
            <router-link to="/" class="btn btn-success">
              <i class="mdi mdi-home me-1"></i>{{ $t('t-back-to-home') }}
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </template>
  
  <script>
  export default {
    props: {
      hasPermissions: Boolean
    }
  };
  </script>
  